<template>

  <div class="mt-3">
    <div class="d-flex mb-3">
      <el-input
        v-if="editar"
        class="input-tipo"
        placeholder="Tipos"
        v-model="newTipo.nome"
        @change="editarTipo()"
      />
      <div
        v-else
        class="style-box pointer"
        @click="show = !show"
      >
        <span>{{tipo.nome}}</span>
        <span class="float-right mr-3">
          <i
            class="pointer"
            :class=" !show? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
          />
        </span>
      </div>

      <span
        class="ml-auto"
        v-if="!editar"
      >
        <el-button
          type="primary"
          icon="el-icon-edit"
          @click="editar = true"
          circle
        />
        <el-button
          type="danger"
          icon="el-icon-delete"
          @click="excluirTipo()"
          circle
        />
      </span>
      <template v-else>

        <div class="ml-2">
          <span>
            <el-button
              type="primary"
              icon="el-icon-check"
              @click="editarTipo()"
              circle
            />
            <el-button
              type="danger"
              icon="el-icon-close"
              @click="concelarEdicao()"
              circle
            />
          </span>
        </div>
      </template>

    </div>
    <template v-if="show">

      <template v-if="returnGruposDoTipo(tipo.id).length > 0">

        <relato-grupo
          v-for="(grupo, i) in returnGruposDoTipo(tipo.id)"
          :key="grupo.id"
          :grupo="grupo"
          :itens="itens"
          :showInput="i === 0"
          v-on:novoGrupo="novoGrupo"
          v-on:novoItem="novoItem"
          v-on:excluirGrupo="excluirGrupo"
          v-on:excluirItem="excluirItem"
          v-on:editarGrupo="editarGrupo"
        >
          <p
            v-if="i === 0"
            slot="titlo_groups"
            class="visualizations__header noselect"
          >Grupos</p>
          <p
            v-if="i === 0"
            slot="title_itens"
            class="visualizations__header noselect"
          >Itens</p>

        </relato-grupo>
      </template>
      <template v-else>

        <relato-grupo
          :grupo="{}"
          :itens="[]"
          :showInput="true"
          v-on:novoGrupo="novoGrupo"
          v-on:novoItem="novoItem"
        >
          <p
            slot="titlo_groups"
            class="visualizations__header noselect"
          >Grupos</p>
          <p
            slot="title_itens"
            class="visualizations__header noselect"
          >Itens</p>

        </relato-grupo>
      </template>

    </template>
  </div>
</template>
<script>
import relatoGrupo from './relatoGrupo.vue';

export default {
  components: {
    relatoGrupo,
  },
  props: {
    tipo: {
      type: Object,
      required: true,
    },
    itens: {
      type: Array,
      required: true,
    },
    grupos: {
      type: Array,
      required: true,
    },
    showInput: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      grupo: {
        nome: '',
      },
      item: {
        nome: '',
        grupo: null,
      },
      newTipo: { nome: '' },
      show: false,
      editar: false,
    };
  },
  mounted() {
    this.newTipo = JSON.parse(JSON.stringify(this.tipo));
  },
  methods: {
    returnGruposDoTipo(id) {
      let resultado = [];
      if (id) {
        resultado = this.grupos.filter(g => (g.tipo === id));
      }
      return resultado;
    },
    novoTipo() {
      this.$emit('novoTipo', this.newTipo);
    },
    novoGrupo(event) {
      const grupo = event;
      grupo.tipo = this.tipo.id;
      this.$emit('novoGrupo', grupo);
    },
    novoItem(item) {
      this.$emit('novoItem', item);
    },
    excluirItem(item) {
      this.$emit('excluirItem', item);
    },
    excluirGrupo(grupo) {
      this.$emit('excluirGrupo', grupo);
    },
    excluirTipo() {
      this.$emit('excluirTipo', this.tipo);
    },
    editarTipo() {
      this.editar = false;
      this.$emit('editarTipo', this.newTipo);
    },
    editarGrupo(grupo) {
      this.$emit('editarGrupo', grupo);
    },
    concelarEdicao() {
      this.newTipo = JSON.parse(JSON.stringify(this.tipo));
      this.editar = false;
    },
  },
  watch: {
    editar() {
      this.show = this.editar ? false : this.show;
    },
  },
};
</script>

<style>
.visualizations__body {
  padding: 0.4rem;
}

.input-tipo {
  margin-bottom: 10px;
  width: 250px;
}
.pointer {
  cursor: pointer;
}
.style-box {
  width: 90% !important;
}
.float-right {
  float: right !important;
}
</style>
