<template>

  <div class="d-flex">
    <div class="visualizations panel mr-3">
      <slot name="titlo_groups"></slot>

      <div class="visualizations__body">
        <el-input
          v-if="showInput"
          placeholder="Grupo"
          @change="novoGrupo"
          maxlength="100"
          v-model="newGrupo.nome"
        >
          <i
            slot="prefix"
            class="el-input__icon el-icon-folder-add"
          ></i>
          <template slot="append">{{lengthGrupos}}/100

          </template>
        </el-input>
        <div class="container">

          <span
            class="row mt-2 align-items-center"
            v-if="grupo.id"
          >
            <label class="w3-animate-opacity el-transfer-panel__item
            noselect col-sm noselect style-hover-options break-word">
              {{grupo.nome}}
            </label>
            <el-button
              class="mb-2 style-btn-itens"
              type="primary"
              icon="el-icon-edit"
              @click="preparaEdicaoGrupo(grupo)"
              circle
            />
            <el-button
              type="danger"
              class="mb-2 style-btn-itens"
              icon="el-icon-delete"
              @click="deletarGrupo()"
              circle
            />
          </span>
          <div
            class="texto_nao_encontrado"
            v-else
          >
            <span>
              Sem grupos
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="visualizations panel">
      <slot name="title_itens"></slot>
      <div class="visualizations__body">

        <el-input
          placeholder="Item"
          v-model="item.nome"
          class="mb-2"
          maxlength="100"
          @change="novoItem"
        >
          <i
            slot="prefix"
            class="el-input__icon el-icon-folder-add"
          ></i>
          <template slot="append">{{lengthItens}}/100</template>

        </el-input>
        <div
          class="container"
          v-if="returnItemDoGrupos(grupo.id).length"
        >
          <span
            class="row align-items-center"
            v-for="item in returnItemDoGrupos(grupo.id)"
            :key="item.id"
          >

            <label class="col-sm-11 el-transfer-panel__item break-word
                noselect style-hover-options w3-animate-opacity ">
              {{item.nome}}

            </label>
            <el-button
              type="danger"
              class="mb-2 style-btn-itens"
              icon="el-icon-delete"
              @click="deletarItem(item)"
              circle
            />
          </span>
        </div>
        <div
          class="texto_nao_encontrado"
          v-else
        >
          <span>Sem itens</span>
        </div>
      </div>
    </div>

    <!-- modal para cadastrar área -->
    <vuestic-modal
      ref="modalgrupoEditar"
      v-on:ok="editarGrupo"
    >
      <div
        slot="title"
      >Editar Grupo</div>
      <div>
        <fieldset>
          <div class="form-group">
            <div class="input-group">
              <p>Grupo:</p>
              <input
                v-model="grupoEditar.nome"
                id="simple-input"
                required
              >
              <label
                class="control-label"
                for="simple-input"
              ></label>
            </div>
          </div>
        </fieldset>
      </div>
    </vuestic-modal>
  </div>
</template>
<script>

export default {
  props: {
    itens: {
      type: Array,
      required: true,
    },
    grupo: {
      type: Object,
      required: true,
    },
    showInput: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newGrupo: {
        nome: '',
        tipo: null,
        modelo: null,
      },
      item: {
        nome: '',
        grupo: null,
      },
      grupoEditar: {},
    };
  },
  methods: {
    returnItemDoGrupos(id) {
      let resultado = [];
      if (id) {
        resultado = this.itens.filter(g => (g.grupo === id));
      }
      return resultado;
    },
    novoGrupo() {
      const grupo = JSON.parse(JSON.stringify(this.newGrupo));
      this.$emit('novoGrupo', grupo);
      this.newGrupo.nome = '';
    },
    preparaEdicaoGrupo(grupo) {
      this.grupoEditar = JSON.parse(JSON.stringify(grupo));
      this.$refs.modalgrupoEditar.open();
    },
    editarGrupo() {
      this.$emit('editarGrupo', this.grupoEditar);
    },
    novoItem() {
      const item = JSON.parse(JSON.stringify(this.item));
      item.grupo = this.grupo.id;
      this.$emit('novoItem', item);
      this.item.nome = '';
    },
    deletarGrupo() {
      this.$emit('excluirGrupo', this.grupo);
    },
    deletarItem(item) {
      this.$emit('excluirItem', item);
    },
  },
  computed: {
    lengthGrupos() {
      return this.newGrupo.nome.length;
    },
    lengthItens() {
      return this.item.nome.length;
    },
  },
};
</script>

<style>
.visualizations__body {
  padding: 0.4rem;
}
.panel {
  width: 49% !important;
}
.texto_nao_encontrado {
  width: 100%;
  height: 100%;
  text-align: center;
}
.style-btn-itens {
  height: max-content !important;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.el-transfer-panel__item {
  cursor: pointer !important;
  height: auto !important;
  min-height: 34px !important;
}
.style-hover-options:hover {
  background-color: #bdbdbd !important;
  border-radius: 4px !important;
  color: #fff !important;
}
.w3-animate-opacity {
  animation: opac 0.8s;
}
.break-word {
  word-wrap: break-word;
}
@keyframes opac {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
